import Link from "next/link";
import React from "react";

function Footer() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 mt-16">
        {/* Top area: Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">
          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-3">
            <div className="mb-2">
              {/* Logo */}
              <Link href="/" className="inline-block" aria-label="Cruip">
                <img
                  src="/icons/android-chrome-192x192-white.png"
                  height="64px"
                  width="64px"
                />
              </Link>
            </div>
          </div>

          {/* 2nd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-gray-800 font-medium mb-2">Products</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <Link
                  href="#"
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  InstaDocket
                </Link>
              </li>
              <li className="mb-2 text-gray-600">
                {"InstaDraft (coming soon!)"}
              </li>
            </ul>
          </div>

          {/* 3rd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-gray-800 font-medium mb-2">Resources</h6>
            <ul className="text-sm">
              {/* <li className="mb-2">
                <a
                  href="https://docs.instadocket.app"
                  target={"_blank"}
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  Tutorials & Guides
                </a>
              </li>
              <li className="mb-2">
                <Link
                  href="/blog"
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  Blog
                </Link>
              </li> */}
              <li className="mb-2">
                <a
                  href="https://wa.me/918341066650"
                  target="_blank"
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  Support
                </a>
              </li>
              <li className="mb-2">
                <Link
                  href="https://docs.google.com/document/d/1g24a3wob29BsDDtaKUHatTEabilP8gF0obixlIOiryg"
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  href="https://docs.google.com/document/d/1zkuQMV4RlljF7cNLHXH-hG-eoeS4MkT61S6JCSX-EL4"
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  target="_blank"
                >
                  Terms of Use
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  href="https://docs.google.com/document/d/1HeZdWwau5Nixh_8hH7dLRZsd7lXOSt4tQJTzXuAr6rM/edit?usp=sharing"
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  target="_blank"
                >
                  Refunds
                </Link>
              </li>
            </ul>
          </div>

          {/* 4th block */}
          {
            <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
              <h6 className="text-gray-800 font-medium mb-2">Company</h6>
              <ul className="text-sm">
                <li className="mb-2">
                  <Link
                    href="/"
                    className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  >
                    Home
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    href="/about"
                    className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  >
                    About us
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    href="/contact"
                    className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          }

          {/* 5th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3"></div>
        </div>

        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
          {/* TODO: Social links 
          <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
            <li>
              <Link
                to="#"
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                aria-label="Twitter"
              >
                <svg
                  className="w-8 h-8 fill-current"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                </svg>
              </Link>
            </li>
            <li className="ml-4">
              <Link
                to="#"
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                aria-label="Facebook"
              >
                <svg
                  className="w-8 h-8 fill-current"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                </svg>
              </Link>
            </li>
          </ul>
          */}
          {/* Copyrights note */}
          <div className="text-sm text-gray-600 mr-4">
            Made in 🇮🇳 India. All rights reserved by Artilegal Technologies LLP.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
