import type { NextLayoutComponentType } from "next";
import Header from "../src/modules/landing-page/components/Header";
import HeroHome from "../src/modules/landing-page/components/HeroHome";
import FeaturesBlocks from "../src/modules/landing-page/components/FeaturesBlocks";
import Footer from "../src/modules/landing-page/components/Footer";
import Head from "next/head";
import { useEffect } from "react";
import AnalyticsService from "../src/modules/analytics/services";

const Home: NextLayoutComponentType = () => {
  useEffect(() => {
    AnalyticsService.trackEvent.landingPageVisited();
  }, []);

  return (
    <>
      <Head>
        <title>InstaDocket App</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="flex-grow">
          {/*  Page sections */}
          <HeroHome />
          <FeaturesBlocks />
        </main>

        {/*  Site footer */}
        <Footer />
      </div>
    </>
  );
};

export default Home;
