import * as amplitude from "@amplitude/analytics-browser";
import { IOrderStatus } from "../orders/types";
import * as Sentry from "@sentry/nextjs";

const AnalyticsService = {
  init: (user_id: string) => {
    try {
      if (process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
        amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, user_id, {
          defaultTracking: {
            sessions: true,
            pageViews: false,
            formInteractions: false,
            fileDownloads: true,
          },
        });
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  },
  identify: (user_id: string) => {
    try {
      if (process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
        const identifyObj = new amplitude.Identify();
        amplitude.identify(identifyObj, {
          user_id,
        });
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  },
  track: (eventName: string, properties?: any) => {
    try {
      if (process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
        amplitude.track(eventName, properties);
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  },
  trackEvent: {
    odtFileUploaded: (file_name: string) => {
      AnalyticsService.track("AA - odt File Uploaded", {
        file_name,
      });
    },
    landingPageVisited: () => {
      AnalyticsService.track("Landing Page Visited", {});
    },
    contactPageVisited: () => {
      AnalyticsService.track("Contact Us Page Visited", {});
    },
    aboutPageVisited: () => {
      AnalyticsService.track("About Us Page Visited", {});
    },
    homePageVisited: () => {
      AnalyticsService.track("Home Page Visited", {});
    },
    userLoggedIn: (
      user_id: string,
      is_new: boolean,
      via: "whatsapp" | "sms"
    ) => {
      AnalyticsService.track("User Logged In", { user_id, is_new, via });
    },
    userLoggedOut: (user_id: string) => {
      AnalyticsService.track("User Logged Out", { user_id });
    },

    userProfileUpdated: (name: string, email: string) => {
      AnalyticsService.track("User Profile Updated", { name, email });
    },
    fileUploaded: (type: string, format: "docx" | "pdf", size: number) => {
      AnalyticsService.track("File Uploaded", { type, format, size });
    },
    orderCreated: (order_id: string, type: string, format: "docx" | "pdf") => {
      AnalyticsService.track("Order Created", {
        order_id,
        type,
        format,
      });
    },
    orderDocketGenerated: (order_id: string) => {
      AnalyticsService.track("Docket Generated", { order_id });
    },
    orderDocketPageViewed: (order_id: string) => {
      AnalyticsService.track("Docket Page Viewed", {
        order_id,
      });
    },
    orderDocketDownloaded: (order_id: string) => {
      AnalyticsService.track("Docket Downloaded", {
        order_id,
      });
    },
    orderDocketPreviewed: (order_id: string) => {
      AnalyticsService.track("Docket Previewed", {
        order_id,
      });
    },
    orderTypeSelected: (id: number) => {
      AnalyticsService.track("Order Type Selected Selected", {
        type: id === 1 ? "Writ" : id === 2 ? "Bail" : "Quash",
      });
    },
    processingCompleted: (order_id: string) => {
      AnalyticsService.track("Initial Processing Completed", { order_id });
    },
    partiesConfirmed: (order_id: string) => {
      AnalyticsService.track("Parties Confirmed", { order_id });
    },
    prayersConfirmed: (order_id: string) => {
      AnalyticsService.track("Prayers Confirmed", { order_id });
    },
    metadataConfirmed: (order_id: string) => {
      AnalyticsService.track("Metadata Confirmed", { order_id });
    },
    newDocketSelected: () => {
      AnalyticsService.track("New Docket Initiated", {});
    },
    installedApp: () => {
      AnalyticsService.track("Installed App", {});
    },
    installAppSelected: (is_ios: boolean) => {
      AnalyticsService.track("Install App Selected", { is_ios });
    },
    installAppDismissed: () => {
      AnalyticsService.track("Install App Dismissed", {});
    },
    notifyMeSelected: (order_id: string, order_status: IOrderStatus) => {
      AnalyticsService.track("Notify Me Selected", { order_id, order_status });
    },
    notifcationsSubscribed: () => {
      AnalyticsService.track("Notifications Subscribed", {});
    },
    bellIconSelected: () => {
      AnalyticsService.track("Bell Icon Selected", {});
    },
    lawyerCreated: (lawyer_id: string) => {
      AnalyticsService.track("Lawyer Created", { lawyer_id });
    },
    lawyerUpdated: (lawyer_id: string) => {
      AnalyticsService.track("Lawyer Updated", { lawyer_id });
    },
    onboardingCTASelected: (text: string = "", location: string = "") => {
      AnalyticsService.track("Onboarding - CTA Selected", { text, location });
    },
    onboardingWhatsappSelected: () => {
      AnalyticsService.track("Onboarding - Whatsapp Selected", {});
    },
    onboardingLoginWithPhoneSelected: () => {
      AnalyticsService.track("Onboarding - Login With Phone Selected", {});
    },
    onboardingPhoneNumberSubmitted: (
      phone_number: string,
      via: "whatsapp" | "sms"
    ) => {
      AnalyticsService.track("Onboarding - Phone Number Submitted", {
        phone_number,
        via,
      });
    },
    onboardingOTPFailed: (phone_number: string, via: "whatsapp" | "sms") => {
      AnalyticsService.track("Onboarding - OTP Failed", {
        phone_number,
        via,
      });
    },
    onboardingOTPResent: (phone_number: string, via: "whatsapp" | "sms") => {
      AnalyticsService.track("Onboarding - OTP Resent", {
        phone_number,
        via,
      });
    },
    referralLinkCopied: () => {
      AnalyticsService.track("Referral Link Copied", {});
    },
    newReferral: (user_id: string, referrer_id: string) => {
      AnalyticsService.track("New Referral", { user_id, referrer_id });
    },
  },
};

export default AnalyticsService;
