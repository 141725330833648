import Link from "next/link";
import React, { useState, useEffect } from "react";
import AnalyticsService from "../../analytics/services";
import { useRouter } from "next/router";

function Header() {
  const [top, setTop] = useState(true);
  const router = useRouter();

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <header
      className={`fixed w-full md:bg-opacity-90 transition duration-300 ease-in-out z-30 ${
        !top && "bg-white backdrop-blur-sm shadow-lg"
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link href="/" className="block" aria-label="Cruip">
              <img
                src="/icons/android-chrome-192x192-white.png"
                height="56px"
                width="56px"
              />
            </Link>
          </div>

          {/* Site navigation */}
          {
            <nav className="flex flex-grow">
              <div className="flex flex-grow justify-end flex-wrap items-center">
                <Link
                  className="w-36 font-medium text-base text-blue-700 flex justify-end"
                  href="/auth/login"
                  onClick={(e) => {
                    e.preventDefault();
                    router.push({
                      pathname: "/auth/login",
                      query: router.query,
                    });
                    AnalyticsService.trackEvent.onboardingCTASelected(
                      "Log in",
                      "header"
                    );
                  }}
                >
                  Log in
                </Link>
              </div>
            </nav>
          }
        </div>
      </div>
    </header>
  );
}

export default Header;
