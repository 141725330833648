import React, { useState } from "react";
import axios from "axios";
import { Button } from "konsta/react";
import PwaInstallButton from "../../../components/PwaInstallButton";
import { useRouter } from "next/router";
import AnalyticsService from "../../analytics/services";

function HeroHome() {
  const router = useRouter();
  return (
    <section className="relative min-h-[105vh]">
      {/* Illustration behind hero content */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none z-0"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative z-1">
        {/* Hero content */}
        <div className="pt-48 lg:pt-24 xl:pt-48 pb-12 md:pb-20 lg:flex lg:items-center lg:gap-4">
          {/* Section header */}
          <div className="text-center pb-8 md:pb-12 grow-[2] basis-80 lg:text-left">
            <h1
              className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4 aos-animate"
              data-aos="zoom-y-out"
            >
              Instant dockets for
              <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                high court filing
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <p
                className="text-xl text-gray-600 mb-4 aos-animate"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                {
                  "Upload documents (like an affidavit) and instantly generate dockets ready to file in Courts"
                }
              </p>
              <div
                className="max-w-full flex flex-col items-center lg:flex-row gap-3 mx-auto lg:mx-0 sm:flex sm:justify-center lg:justify-start aos-animate"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <Button
                  large
                  className="h-15 rounded-lg max-w-[300px]"
                  onClick={() => {
                    router.push({
                      pathname: "/auth/login",
                      query: router.query,
                    });
                    AnalyticsService.trackEvent.onboardingCTASelected(
                      "Try for free",
                      "hero"
                    );
                  }}
                >
                  Try for free
                </Button>
                {/* <PwaInstallButton /> */}
              </div>
            </div>
          </div>

          {/* Hero image */}
          <div className="grow basis-40">
            <div
              className="relative flex justify-center aos-animate"
              data-aos="zoom-y-out"
              data-aos-delay="450"
            >
              <div className="relative pt-[150%] w-full">
                <iframe
                  src="https://player.vimeo.com/video/851390780?h=4ce2a75ee8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;portrait=0&amp;byline=0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  className=" absolute top-0 left-0 w-full h-full"
                  title="InstaDocket Demo"
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
